
                    @import "src/styles/_mixins.scss";
                
.wrapper {
  width: 600px;
  position: fixed;
  top: 0;
  right: 0;
  background: var(--secondary-100);
  z-index: 10;
  height: 100vh;
  overflow-y: auto;
  overflow-x: visible;
  border-left: 1px solid var(--Neutral-N-60, #A8A5A2);
  padding: 113px 32px;
  transform: translateX(600px);
  transition: .3s;

  &.isOpen {
    transform: translateX(0) !important;
  }

  .close {
    position: absolute;
    left: 43px;
    top: 36px;
  }
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 9;
  opacity: 0.2;
  background: var(--secondary-20);
}
