
                    @import "src/styles/_mixins.scss";
                
@import 'mixins';

:root {
    --primary-10: #9A6609;
    --primary-20: #BE8317;
    --primary-30: #DB9E2F;
    --primary-40: #EDB755;
    --primary-50: #F5CC82;
    --primary-60: #FFDFA6;
    --primary-70: #FFE9C0;
    --primary-80: #FDF1DC;
    --primary-90: #FFF8EC;
    --primary-100: #FFFDFA;

    --secondary-10: #1D1D1D;
    --secondary-20: #000;
    --secondary-30: #3D3D3D;
    --secondary-40: #4E4C47;
    --secondary-50: #666259;
    --secondary-60: #B5AE9F;
    --secondary-70: #D1CCBE;
    --secondary-80: #E1DCD0;
    --secondary-90: #F8F6F1;
    --secondary-100: #FDFDFD;

    --tertiary-10: #372505;
    --tertiary-20: #4A3309;
    --tertiary-30: #6B4706;
    --tertiary-40: #895609;
    --tertiary-50: #986820;
    --tertiary-60: #CBA366;
    --tertiary-70: #E2C292;
    --tertiary-80: #F4DCB8;
    --tertiary-90: #FFF4E4;
    --tertiary-100: #FFFBF5;

    --error-10: #410E0B;
    --error-20: #601410;
    --error-30: #8C1D18;
    --error-40: #B3261E;
    --error-50: #DC362E;
    --error-60: #E46962;
    --error-70: #EC928E;
    --error-80: #F2B8B5;
    --error-90: #F9DEDC;
    --error-100: #FFFBF5;

    --success-10: #064828;
    --success-20: #0A673A;
    --success-30: #0C884C;
    --success-40: #0F9D58;
    --success-50: #1DAF68;
    --success-60: #46BD83;
    --success-70: #69C598;
    --success-80: #ADE0C7;
    --success-90: #D1F0E1;
    --success-100: #F8FFFB;

    --neutral-10: #0C0C0B;
    --neutral-20: #201E1B;
    --neutral-30: #353535;
    --neutral-40: #66625B;
    --neutral-50: #84807B;
    --neutral-60: #A8A5A2;
    --neutral-70: #D3D1CF;
    --neutral-80: #F2F1EE;
    --neutral-90: #F8F8F8;
    --neutral-100: #FFF;
}

body {
    margin: 0;
    padding: 0;
}

* {
    box-sizing: border-box;
    font-family: Poppins, sans-serif;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
span,
ul,
li {
    margin: 0;
    padding: 0;
    color: var(--secondary-20)
}

li {
    list-style-type: none;
}

svg {
    color: var(--secondary-30);
}

button, label,
a {
    cursor: pointer;
    border: none;
    background: none;
    padding: 0;
    transition: .3s;
}

// media

.table_filter_dropdown {
    position: absolute;
    top: 44px;
    left: 0;
    width: max-content;
    border: 1px solid var(--neutral-70);
    background: var(--Neutral-N-90, #f8f8f8);
    z-index: 2;
    height: max-content;
    display: none;

    .item {
        display: flex;
        align-items: center;
        gap: 10px;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%;
        color: #000;
        padding: 2px 16px;

        &:hover {
            background: var(--primary-90);
        }

        &.isActive {
            color: var(--primary-20);
            background: var(--primary-90);
        }
    }
}

.status_dropdown {
    position: absolute !important;
    display: none;
    z-index: 2;
    margin-top: 8px;
    top: 20px;
    width: 200px;
    border-radius: 4px;
    border: none;
    background: var(--neutral-100);
    flex-direction: column;
    padding: 0;
    transition: .3s;
    overflow-y: auto !important;
    max-height: 200px;
    right: 10px;

    &.isOpen {
        border: 1px solid var(--neutral-70);
        padding: 4px 0;
        display: flex;
    }

    button {
        padding: 8px 12px;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        color: var(--secondary-20);
        transition: .3s;
        text-align: left;

        &:hover {
            background: var(--primary-80);
        }
    }
}

input:disabled {
    background: white
}

.back {
    width: max-content;
    text-decoration: underline;
    display: flex;
    align-items: center;
    gap: 4px;
    margin-bottom: 8px;
}

.pac-container {
    z-index: 2;
    margin-top: 8px;
    top: 52px;
    width: 100%;
    border-radius: 4px;
    border: none;
    background: var(--neutral-100);
    display: flex;
    flex-direction: column;
    transition: .3s;
    overflow-y: auto !important;
    max-height: 200px;
    padding: 4px 0;

    .pac-item {
        cursor: pointer;
        border-top: none;
        padding: 8px 12px;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        color: var(--secondary-20);
        transition: .3s;
        display: flex;
        align-items: center;
        text-align: left;

        .pac-icon {
            margin-top: 0;
        }

        &:hover {
            background: var(--primary-80);
        }
    }

    &:after {
        display: none;
    }
}

.dropdown_style {
    display: none;
    position: absolute !important;
    z-index: 2;
    margin-top: 8px;
    top: 20px;
    width: 200px;
    border-radius: 4px;
    border: none;
    background: var(--neutral-100);
    flex-direction: column;
    padding: 0;
    transition: .3s;
    overflow-y: auto !important;
    max-height: 200px;
    height: 0;
    right: 10px;

    &.isOpen {
        height: max-content;
        border: 1px solid var(--neutral-70);
        display: flex;
        padding: 4px 0;
    }

    button {
        padding: 8px 12px;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        color: var(--secondary-20);
        transition: .3s;
        text-align: left;

        &:hover {
            background: var(--primary-80);
        }
    }
}