
                    @import "src/styles/_mixins.scss";
                
.wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;

  .header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 22px;

    .text_wrapper {
      display: flex;
      flex-direction: column;
      gap: 10px;
    }
  }

  .content {
    border: solid var(--neutral-70);
    border-width: 0 0.5px 0.5px 0.5px;
  }
}
