
                    @import "src/styles/_mixins.scss";
                
.wrapper {
  position: relative;
  width: max-content;

  &.isFull {
    width: 100%;
  }

  svg {
    transition: .3s;
  }

  & .isOpen {
    svg {
      transform: rotate(180deg);
    }
  }

  .dropdown_wrapper {
    position: absolute;
    z-index: 2;
    width: 100%;

    .dropdown {
      margin-top: 8px;
      width: 100%;
      border-radius: 4px;
      background: var(--neutral-100);
      display: flex;
      flex-direction: column;
      padding: 0;
      transition: .3s;
      overflow-y: auto;
      max-height: 200px;
      height: 0;
      border: none;

      button {
        padding: 8px 12px;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        color: var(--secondary-20);
        transition: .3s;
        text-align: left;
        display: flex;
        align-items: center;
        gap: 8px;

        label {
          width: 24px;
          height: 24px;
          border-radius: 4px;

          svg {
            width: 16px;
            height: 16px;
            top: 4px;
            left: 4px;
            transform: rotate(0);
          }
        }

        &:hover {
          background: var(--primary-80);
        }
      }
    }

    &.isOpen {
      .dropdown {
        height: max-content;
        padding: 4px 0;
        border: 1px solid var(--neutral-70);
      }
    }
  }
}
