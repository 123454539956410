
                    @import "src/styles/_mixins.scss";
                
.wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-top: 16px;

  .header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 8px;

    .text_wrapper {
      display: flex;
      flex-direction: column;
      gap: 10px;
    }

    .actions {
      display: flex;
      align-items: center;
      gap: 16px;
    }
  }

  .drawer, .logs_drawer {
    display: flex;
    flex-direction: column;
    gap: 24px;

    .date_accordion {
      display: flex;
      align-items: center;
      gap: 16px;
      margin-bottom: 24px;
    }
  }

  .logs_drawer {

    .title {
      width: 100%;
      padding-bottom: 16px;
      border-bottom: 1px solid var(--secondary-80);
    }
    .row {
      display: flex;
      align-items: center;
      gap: 16px;
    }
  }
}
