
                    @import "src/styles/_mixins.scss";
                
.wrapper {
  width: 100%;
  overflow-x: auto;

  .table {
    border: 2px solid var(--neutral-70);
    min-width: 100%;
    width: max-content;
    border-collapse: collapse;

    .header {
      border-bottom: 1px solid var(--neutral-70);
      background: var(--neutral-80);

      th {
        font-size: 16px;
        font-weight: 500;
        line-height: 150%;
        color: var(--secondary-10);
        width: max-content;
        text-align: left;
        position: relative;

        &.isSort {
          cursor: pointer;
        }

        & > div {
          display: flex;
          align-items: center;
          gap: 12px;
          justify-content: space-between;
          position: relative;
          min-width: 121px;

          svg {
            transition: .3s;

            &.isDesc {
              transform: rotate(180deg);
            }
          }

          .dropdown {
            position: absolute;
            top: 44px;
            left: 0;
            width: max-content;
            border: 1px solid var(--neutral-70);
            background: var(--Neutral-N-90, #F8F8F8);
            z-index: 2;
            height: max-content;

            .item {
              display: flex;
              align-items: center;
              gap: 10px;
              font-size: 16px;
              font-style: normal;
              font-weight: 400;
              line-height: 150%;
              color: #000;
              padding: 2px 16px;

              &:hover {
                background: var(--primary-90);
              }

              &.isActive {
                color: var(--primary-20);
                background: var(--primary-90);
              }
            }
          }
        }

        &:not(:last-child) {
          & > div {
            margin: 16px 0;
            &::before {
              content: '';
              background: var(--neutral-70);
              width: 1px;
              position: absolute;
              right: 0;
              height: 24px;
              z-index: 1;
            }
          }
        }

        &:not(:first-child) {
          & > div {
            padding: 0 8px;
          }
        }

        &:first-child {
          padding-left: 24px;
        }

        &:last-child {
          padding-right: 24px;
        }
      }
    }

    .body {

      tr {
        transition: .3s;

        &.isOnclick {
          cursor: pointer;
        }

        &:nth-child(2n + 1) {
          background: var(--neutral-90);
        }

        &:hover {
          background: var(--primary-90);
        }
      }

      td {
        font-size: 16px;
        font-weight: 500;
        line-height: 150%;
        color: var(--secondary-10);
        width: max-content;
        text-align: left;
        position: relative;

        &:not(:last-child) {
          & > div {
            margin: 16px 16px 16px 0;
          }
        }

        &:not(:first-child) {
          & > div {
            padding: 0 8px;
          }
        }

        &:first-child {
          padding-left: 24px;
        }

        &:last-child {
          padding-right: 24px;
        }
      }
    }
  }

  .empty_wrapper {
    margin-top: 16px;
    padding: 16px;
    width: 100%;
    border-radius: 8px;
    border: 1px solid var(--secondary-80);
    background: #FFF;
  }
}
