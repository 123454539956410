
                    @import "src/styles/_mixins.scss";
                
.wrapper {
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 16px;
  width: 420px;

  @include mobile {
    margin: 0 16px;
    width: calc(100% - 32px);
  }

  .actions {
    display: flex;
    align-items: center;
    gap: 16px;
  }
}