
                    @import "src/styles/_mixins.scss";
                
.wrapper {
  display: flex;
  align-items: center;
  max-width: 100%;
  overflow-x: auto;
  scrollbar-width: none;

  .tab {
    border-radius: 15px 15px 0 0;
    border: 0.5px solid var(--neutral-70);
    background: var(--Neutral-N-100, #FFF);
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 16px 0;
    min-width: 220px;
    cursor: pointer;
    transition: .3s;

    p {
      font-size: 18px;
      font-weight: 400;
      line-height: 150%;
      color: var(--primary-20);
    }

    &.isActive {
      background: var(--secondary-30);

      p {
        color: var(--primary-50);
      }
    }
  }
}
