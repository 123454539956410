
                    @import "src/styles/_mixins.scss";
                
.wrapper {
  display: flex;
  flex-direction: column;

  .header {
    padding: 16px 0;
    border-bottom: 1px solid var(--secondary-80);
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .content {
    padding: 16px 0;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 24px;

    & > div {
      max-width: calc(25% - 18px);
    }
  }

  &.full {
    .content {
      & > div {
        max-width: 100%;
      }
    }
  }
}
