
                    @import "src/styles/_mixins.scss";
                
.root {
  width: 52px;
  height: 32px;
  position: relative;
  border-radius: 100px;
  border: 2px solid var(--secondary-30);
  display: block;

  & > span {
    width: 16px;
    height: 16px;
    border-radius: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 6px;
    transform: translateX(6px);
    transition: .3s;
    background: var(--secondary-30);
    color: var(--neutral-100);

    svg {
      width: 16px;
      height: 16px;
    }

    &:before {
      content: "";
      position: absolute;
      width: 48px;
      height: 48px;
      border-radius: 100px;
      z-index: 1;
      transition: .3s;
    }
  }

  &:hover {
    & > span {
      &:before {
        background: rgba(3, 3, 3, .08);
      }

      svg {
        color: var(--primary-70);
      }
    }
  }

  input {
    display: none;
  }

  &.checked {
    background: var(--primary-30);
    border-color: var(--primary-30);

    & > span {
      left: auto;
      transform: translateX(22px);
      width: 24px;
      height: 24px;
      top: 2px;
      background: var(--neutral-100);

      svg {
        color: var(--primary-30);
      }
    }

    &:hover {
      & > span {
        &:before {
          background: rgba(219, 158, 47, 0.08);
        }
      }
    }
  }

  //&.disabled {
  //  background: var(--secondary-80);
  //
  //  & > span {
  //    background: #FEF7FF;
  //  }
  //}
}
