
                    @import "src/styles/_mixins.scss";
                
.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;

  @include mobile {
    width: 100%;
  }

  a {
    font-weight: 600;
  }

  .title {
    margin-bottom: 10px;
    text-align: center;

    @include tablet {
      margin-bottom: 8px;
    }
  }

  .description {
    margin-bottom: 48px;

    @include tablet {
      margin-bottom: 32px;
    }

    @include mobile {
      margin-bottom: 16px;
    }
  }

  .form {
    width: 380px;
    margin-bottom: 71px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 28px;

    @include tablet {
      margin-bottom: 18px;
    }

    @include mobile {
      margin-bottom: 24px;
      width: 100%;
      gap: 16px;
    }

    & > button {
      margin-top: 45px;

      @include tablet {
        margin-top: 32px;
      }

      @include mobile {
        margin-top: 21px;
      }
    }
  }

  .forgot {
    align-self: flex-start;
    margin-top: -24px;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    color: var(--neutral-30);

    @include mobile {
      margin-top: -12px;
    }
  }
}



@include mobile {

}
