
                    @import "src/styles/_mixins.scss";
                
.wrapper {
    position: relative;
    min-height: 100vh;
    display: flex;
    align-items: stretch;
    gap: 24px;

    .content {
        padding: 90px 24px 90px 0;
        width: 100%;
        height: 100vh;
        overflow-y: auto;

        &.isBack {
            padding-top: 102px;
        }
    }

    .user_wrapper {
        display: flex;
        align-items: center;
        width: 100%;
        justify-content: flex-end;
        gap: 12px;
        margin-top: -52px;
        margin-bottom: 12px;

        .name {
            font-size: 20px;
            font-style: normal;
            font-weight: 400;
            line-height: 150%;
        }

        .avatar {
            width: 40px;
            height: 40px;
            border-radius: 100px;
            background: var(--primary-80);
            display: flex;
            align-items: center;
            justify-content: center;

            p {
                color: var(--neutral-30);
                font-size: 20px;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
            }
        }
    }

    .back_button {
        margin-bottom: 27px;
        padding: 0;
        margin-left: -4px;
        font-size: 16px;
        text-decoration: underline;
    }
}
