
                    @import "src/styles/_mixins.scss";
                
.main_wrapper {
  display: flex;
  flex-direction: column;
  gap: 8px;

  .wrapper {
    display: flex;
    width: 100%;

    .input {
      padding-left: 10px !important;
      border-bottom-left-radius: 0 !important;
      border-top-left-radius: 0 !important;
    }
  }
}
