
                    @import "src/styles/_mixins.scss";
                
.wrapper {
  position: relative;
  width: max-content;

  &.isFull {
    width: 100%;
  }

  svg {
    transition: .3s;
    cursor: pointer;
  }

  & .isOpen {
    svg {
      transform: rotate(180deg);
    }
  }

  .dropdown {

  }
}
