
                    @import "src/styles/_mixins.scss";
                
.wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;

  .header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding: 16px 24px;

    .text_wrapper {
      display: flex;
      flex-direction: column;
      gap: 10px;
    }
  }

  .actions_wrapper {
    display: flex;
    align-items: center;
    gap: 12px;
  }

  .drawer {
    display: flex;
    flex-direction: column;
    gap: 24px;

    .row {
      display: flex;
      align-items: center;
      gap: 16px;
    }
    .title {
      width: 100%;
      padding-bottom: 16px;
      border-bottom: 1px solid var(--secondary-80);
    }
  }
}
