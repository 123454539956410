
                    @import "src/styles/_mixins.scss";
                
.wrapper {
  display: flex;
  flex-direction: column;
  align-self: center;
  height: 100%;
  min-height: 100vh;
  width: 100%;

  .mobile_progress {
    display: none;
    margin-top: 60px;

    @include mobile {
      display: flex;
    }
  }
}
