
                    @import "src/styles/_mixins.scss";
                
.wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;

  @include mobile {
    gap: 16px;
  }

  .address_wrapper {
    display: flex;
    gap: 24px;
    flex-direction: column;
    width: 100%;

    @include mobile {
      gap: 16px;
    }

    .row {
      display: flex;
      align-items: center;
      gap: 24px;

      @include mobile {
        gap: 16px;
      }
    }
  }
}
