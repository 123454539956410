
                    @import "src/styles/_mixins.scss";
                
.wrapper {
  display: flex;
  flex-direction: column;

  .title {
    display: flex;
    align-items: center;
    cursor: pointer;

    svg {
      transition: .3s;
      transform: rotate(-90deg);
    }

    .opened {
      transform: rotate(0);
    }
  }

  .content {
    height: 0;
    transition: .3s;
    overflow: hidden;

    &.isOpen {
      height: max-content;
      overflow: visible;
      padding-top: 24px;
    }
  }
}
