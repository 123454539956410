
                    @import "src/styles/_mixins.scss";
                
.wrapper {
  min-height: 100vh;
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 128px;

  @include tablet {
    gap: 64px;
  }

  @include mobile {
    gap: 40px;
  }

  .content {
    display: flex;
    justify-content: center;

    @include mobile {
      padding: 0 24px;
    }
  }

  .planet {
    position: absolute;
    right: 0;
    bottom: 0;
    z-index: -1;
    width: 483px;

    img {
      width: 100%;
    }

    @include tablet {
      width: 285px;
      height: 282px;
    }

    @include mobile {
      display: none;
    }
  }
}
