
                    @import "src/styles/_mixins.scss";
                
.wrapper {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;

  span {
    transform: scale(0);
    border-radius: 100%;
    position: absolute;
    opacity: 0.8;
    animation-name: ripple;
    animation-duration: 1s;

    &.dark {
      background: #1D1B20;
    }

    &.red {
      background: var(--error-40);
    }

    &.gold {
      background: var(--primary-40);
    }
  }

  @keyframes ripple {
    to {
      opacity: 0;
      transform: scale(2);
    }
  }
}
