
                    @import "src/styles/_mixins.scss";
                
.root {
    font-size: 20px;
    font-weight: 700;
    line-height: 150%;
    color: var(--primary-50);
    text-decoration: none;
    padding: 8px 14px;
    display: flex;
    align-items: center;
    gap: 16px;
    width: 100%;
    transition: .3s;

    svg, p {
        color: var(--primary-50);
    }

    @include tablet {
        padding: 4px 12px;
    }

    &.isActive,
    &:hover {
        border-radius: 30px;
        background: var(--secondary-30);
    }

    @include tablet {
        font-size: 18px;
    }
}
