
                    @import "src/styles/_mixins.scss";
                
.wrapper {
  max-width: 1300px;
  width: 100%;
  padding: 48px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  height: calc(80vh - 152px);

  @include mobile {
    height: max-content;
  }

  .user_wrapper {
    display: flex;
    align-items: center;
    gap: 12px;

    .name {
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 150%;
    }

    .date {
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 140%;
      color: var(--neutral-40);
    }

    .avatar {
      width: 40px;
      height: 40px;
      border-radius: 100px;
      background: var(--primary-80);
      display: flex;
      align-items: center;
      justify-content: center;

      p {
        color: var(--neutral-30);
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
      }
    }
  }

  .img {
    border-radius: 8px;
    border: 1.002px solid var(--Secondary-S-80, #E1DCD0);
    width: 100%;
    height: calc(100% - 52px);

    @include mobile {
      height: auto;
    }
  }
}