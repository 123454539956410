
                    @import "src/styles/_mixins.scss";
                
.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-top: 82px;
  padding: 0 32px;

  @include tablet {
    margin-top: 40px;
  }

  @include mobile {
    margin-top: 20px;
    padding: 0 16px;
  }

  img {
    max-width: 400px;
  }

  .big_text {
    color: var(--primary-30);
    text-align: center;
    font-size: 50px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%;
  }

  .button {
    margin-top: 32px;
  }
}