
                    @import "src/styles/_mixins.scss";
                
@import '@styles/global';

.root {
    color: var(--secondary-20);
    font-size: 18px;
    line-height: 150%;
    font-weight: 400;

    &.h1 {
        font-size: 56px;
        font-style: normal;
        font-weight: 700;
        line-height: 120%;

        @include tablet {
            font-size: 48px;
        }

        @include mobile {
            font-size: 24px;
        }
    }

    &.h2 {
        font-size: 48px;
        font-style: normal;
        font-weight: 700;
        line-height: 120%;

        @include tablet {
            font-size: 32px;
        }

        @include mobile {
            font-size: 20px;
        }
    }

    &.h3 {
        font-size: 32px;
        font-style: normal;
        font-weight: 700;
        line-height: 120%;

        @include tablet {
            font-size: 24px;
        }

        @include mobile {
            font-size: 18px;
        }
    }

    &.h4 {
        font-size: 24px;
        font-style: normal;
        font-weight: 700;
        line-height: 120%;

        @include tablet {
            font-size: 18px;
        }

        @include mobile {
            font-size: 16px;
        }
    }

    &.h5 {
        font-size: 18px;
        font-style: normal;
        font-weight: 700;
        line-height: 120%;

        @include tablet {
            font-size: 16px;
        }

        @include mobile {
            font-size: 14px;
        }
    }

    &.body1 {
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%;

        @include tablet {
            font-size: 18px;
        }
    }

    &.body2 {
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%;

        @include tablet {
            font-size: 16px;
        }
    }

    &.body3 {
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%;

        @include tablet {
            font-size: 14px;
        }
    }

    &.bold {
        font-weight: 700;
    }

    &.medium {
        font-weight: 500;
    }

    &.thin {
        font-weight: 300;
    }

    &.semibold {
        font-weight: 600;
    }
}
