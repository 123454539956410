
                    @import "src/styles/_mixins.scss";
                
.wrapper {
  border-radius: 4px;
  height: 4px;
  width: 100%;
  margin-top: 25px;

  .point {
    width: 20px;
    height: 20px;
    border-radius: 100%;
    background: var(--primary-30);
    position: absolute;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.25);

    &.isDragged {
      border: 10px solid var(--secondary-90);
      width: 40px;
      height: 40px;
    }

    span {
      position: absolute;
      top: -40px;
      left: -5px;
      color: var(--neutral-90);
      font-size: 10px;
      font-weight: 500;
      line-height: 16px;
      letter-spacing: 0.5px;
      padding: 6px 4px;
      background-repeat: no-repeat;
      background-size: cover;
      height: 34px;
      width: 30px;
      text-align: center;
    }
  }
}
