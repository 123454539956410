
                    @import "src/styles/_mixins.scss";
                
.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  gap: 56px;
  padding: 59px 0;
  background: var(--secondary-20);
  height: 100vh;
  position: relative;
  transition: .3s;
  width: 80px;

  &.isOpen {
    width: 360px;
    padding: 59px 30px;
  }

  .toggle_button {
    position: absolute;
    right: -24px;
    top: 40px;
    min-width: max-content !important;
    padding: 12px;

    &.isOpen {
      transform: rotate(180deg);
    }
  }

  .logout {
    min-width: max-content;
    align-self: flex-start;
    font-size: 18px;

    svg {
      transform: rotate(-90deg);
    }
  }

  .top {
    display: flex;
    flex-direction: column;
    gap: 56px;
  }

  .header {
    align-items: center;
    gap: 10px;
    color: white;
    font-size: 24px;
    font-weight: 400;
    line-height: 120%;
    transition: .3s;
    opacity: 0;
    display: none;
    width: 0;
    overflow: hidden;

    &.isOpen {
      opacity: 1;
      display: flex;
      width: max-content;
      overflow: auto;
    }
  }

  .navigation {
    display: flex;
    flex-direction: column;
    gap: 16px;
    width: 100%;
    margin-top: 85px;

    &.isOpen {
      margin-top: 0;
    }

    p {
      width: 0;
      overflow: hidden;
      transition: .3s;
      margin-left: -16px;

      &.isOpen {
        width: max-content;
        overflow: auto;
        margin-left: 0;
      }
    }
  }
}
