
                    @import "src/styles/_mixins.scss";
                
.wrapper {
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin-bottom: 24px;

  .content {
    display: flex;
    flex-direction: column;
    gap: 16px;

    .row {
      display: flex;
      gap: 16px;
    }
  }
}