
                    @import "src/styles/_mixins.scss";
                
.wrapper {
  display: flex;
  flex-direction: column;
  padding: 24px 24px;
  width: 100%;

  .content {
    display: flex;
    width: 100%;
    flex-direction: column;
    gap: 16px;

    .item {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 16px;
      font-weight: 400;
      line-height: 150%;

      .title {
        display: flex;
        align-items: flex-start;
        gap: 8px;
        width: 300px;
      }

      .gray {
        color: var(--neutral-60);
      }
    }
  }

  .drawer {
    display: flex;
    flex-direction: column;
    gap: 24px;

    .title {
      width: 100%;
      padding-bottom: 16px;
      border-bottom: 1px solid var(--secondary-80);
    }

    .drawer_content {
      display: flex;
      flex-wrap: wrap;
      gap: 24px;

      & > div:not(:last-child) {
        max-width: calc(50% - 12px)
      }
    }
  }
}
