
                    @import "src/styles/_mixins.scss";
                
.red {
  color: var(--error-40);
}

.green {
  color: var(--success-40);
}
