
                    @import "src/styles/_mixins.scss";
                
.wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;

  .header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding: 16px 24px;

    .text_wrapper {
      display: flex;
      gap: 10px;
      align-items: center;

      i {
        cursor: pointer;
        padding-bottom: 0;
        border-bottom: none;
        text-decoration: underline;
        font-style: normal;
        display: flex;
        align-items: center;
        gap: 8px;
      }
    }
  }

  .actions_wrapper {
    display: flex;
    align-items: center;
    gap: 12px;
  }

  .drawer {
    display: flex;
    flex-direction: column;
    gap: 24px;

    .title {
      width: 100%;
      padding-bottom: 16px;
      border-bottom: 1px solid var(--secondary-80);
    }

    .content {
      display: flex;
      flex-wrap: wrap;
      gap: 24px;

      & > div {
        max-width: calc(50% - 12px)
      }
    }
  }
}
