
                    @import "src/styles/_mixins.scss";
                
.wrapper {
  position: relative;
  display: inline-block;
  height: 18px;

  .content {
    visibility: hidden;
    background: var(--secondary-30);
    border-radius: 5px;
    padding: 8px;
    position: absolute;
    z-index: 1;
    opacity: 0;
    transition: opacity 0.3s;
    color: var(--secondary-100);
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
    border: 1px solid #000;
    width: max-content;
    max-width: 218px;

    span {
      color: var(--secondary-100);
      font-weight: 700;
    }

    &::after {
      content: '';
      display: block;
      position: absolute;
      width: 0;
      height: 0;
      border-left: 10px solid transparent;
      border-right: 10px solid transparent;
      border-bottom: 15px solid var(--secondary-30);
    }

    &.right {
      left: 100%;
      top: 50%;
      transform: translateX(10px) translateY(-20px);

      &::after {
        left: 7px;
        top: 16px;
        transform: translateX(-100%) translateY(-50%) rotate(225deg);
        border-width: 10px 15px 15px 0;
        border-color: transparent var(--secondary-30) transparent transparent;
      }
    }

    &.top {
      bottom: 100%;
      left: 50%;
      transform: translateX(-50%) translateY(-10px);

      &::after {
        top: 100%;
        left: calc(50% + 6px);
        transform: translateX(-100%) translateY(-50%) rotate(135deg);
        border-width: 10px 15px 15px 0;
        border-color: transparent var(--secondary-30) transparent transparent;
      }
    }

    &.left {
      right: 100%;
      top: 50%;
      transform: translateX(-10px) translateY(-20px);

      &::after {
        right: 7px;
        top: 20px;
        transform: translateX(100%) translateY(-50%) rotate(125deg);
        border-width: 10px 0 10px 15px;
        border-color: transparent transparent transparent var(--secondary-30);
      }
    }

    &.bottom {
      top: 100%;
      left: 50%;
      transform: translateX(-50%) translateY(10px);

      &::after {
        bottom: 100%;
        left: calc(50% - 10px);
        border-width: 0 10px 15px 10px;
        border-color: transparent transparent var(--secondary-30) transparent;
      }
    }
  }

  &.isMaxWidth {
    .content {
      width: max-content;
    }
  }

  &:hover .content {
    visibility: visible;
    opacity: 1;
  }
}