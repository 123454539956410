
                    @import "src/styles/_mixins.scss";
                
.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;

  @include mobile {
    width: 100%;
  }

  .title {
    margin-bottom: 10px;
    text-align: center;

    @include tablet {
      margin-bottom: 8px;
    }
  }

  .description {
    margin-bottom: 57px;
    max-width: 572px;
    text-align: center;

    @include tablet {
      margin-bottom: 41px;
    }

    @include mobile {
      margin-bottom: 24px;
    }

    span {
      font-weight: 600;
    }
  }

  .form {
    width: 380px;
    margin-bottom: 71px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 24px;

    @include mobile {
      width: 100%;
      gap: 16px;
    }

    .next {
      margin-top: 48px;
      margin-bottom: 48px;
      padding: 14px 25px;

      @include tablet {
        margin-top: 32px;
        margin-bottom: 32px;
      }

      @include mobile {
        margin-top: 34px;
        margin-bottom: 26px;
      }
    }

    .back {
      padding: 0;
      font-weight: 400;
      text-decoration: underline;
    }
  }
}
