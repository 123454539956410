
                    @import "src/styles/_mixins.scss";
                
.wrapper {
  width: 100%;
  height: 100%;

  .messages_wrapper {
    overflow-y: scroll;
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 20px;
    width: 100%;
    background: var(--primary-100);
    height: 100%;
    padding: 24px 16px;
    background: var(--primary-100);
    border-top: 1px solid var(--secondary-80);
    padding-bottom: 270px;
  }

  .date_divider {
    position: relative;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    span {
      color: var(--neutral-50);
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 150%;
      margin: 4px 0;
      background: var(--primary-100);
      padding: 0 16px;
      z-index: 10;
    }

    &:before {
      content: ' ';
      width: 100%;
      height: 1px;
      background: var(--neutral-80);
      position: absolute;
      top: 13px;
      left: 0;
    }
  }

  .message_container {
    width: 100%;
    max-width: 500px;
    display: flex;
    align-items: flex-start;
    gap: 16px;
    align-self: flex-start;

    .message_wrapper {
      display: flex;
      flex-direction: column;
      gap: 12px;
      max-width: calc(100% - 56px);
      width: max-content;

      .message {
        background: var(--neutral-90);
        border-radius: 8px 8px 8px 2px;
        padding: 16px;
        display: flex;
        align-items: flex-end;
        justify-content: space-between;
        gap: 4px;
        word-wrap: break-word;
        overflow-wrap: break-word;

        & > div:first-child {
          word-wrap: break-word;
          overflow-wrap: break-word;
          width: 100%;
        }

        .time {
          margin-left: 12px;
          color: var(--neutral-50);
          text-align: right;
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: 150%;
        }
      }
    }

    .avatar {
      width: 40px;
      height: 40px;
      border-radius: 100px;
      background: var(--primary-80);
      display: flex;
      align-items: center;
      justify-content: center;

      p {
        color: var(--neutral-30);
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
      }
    }

    .attachments_wrapper {
      padding: 16px;
      max-width: 300px;
      background: var(--neutral-90);
      border-radius: 8px 8px 8px 2px;
      gap: 8px;
      display: flex;
      flex-direction: column;

      img {
        cursor: pointer;
      }

      .attachments {
        display: flex;
        flex-wrap: wrap;
        gap: 8px;

        img {
          width: calc(50% - 4px);
          height: auto;
          object-fit: cover;
          cursor: pointer;
        }
      }
    }

    &.isSender {
      align-self: flex-end;
      flex-direction: row-reverse;

      .attachments_wrapper {
        border-radius: 8px 8px 2px 8px;
        background: var(--primary-80);
      }

      .message {
        background: var(--primary-80);
        border-radius: 8px 8px 2px 8px;
      }
    }
  }

  .input_wrapper {
    width: 100%;
    padding: 16px 32px;
    border-top: 1px solid var(--neutral-70);
    position: absolute;
    bottom: 0;
    left: 0;
    background: white;
    z-index: 10;

    &.withoutInputBorder {
      padding: 0;
    }

    & .withoutInputBorder {
      input {
        border: none;
      }
    }
  }

  .new_attachments {
    display: flex;
    align-items: center;
    gap: 8px;
    margin-bottom: 8px;

    .info {
      width: 100%;
      height: 100%;
    }

    .attachment {
      width: 48px;
      height: 48px;
      position: relative;
      border-radius: 4px;
      border: 1px solid var(--neutral-80);
      background: #FFF;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;

      svg {
        color: var(--neutral-60);
      }

      img {
        width: 100%;
        height: 100%;
        border-radius: 4px;
      }

      button {
        position: absolute;
        width: 18px;
        height: 18px;
        top: -4px;
        right: -4px;
        background: var(--secondary-30);
        border-radius: 100px;

        svg {
          color: white;
        }
      }
    }

    .add_attachment {
      border-radius: 4px;
      background: var(--Neutral-N-90, #F8F8F8);
      width: 48px;
      height: 48px;
      display: flex;
      align-items: center;
      justify-content: center;

      svg {
        color: var(--neutral-40);
      }
    }
  }
}