
                    @import "src/styles/_mixins.scss";
                
.dialog {
  border: none;
  border-radius: 20px;
  max-height: 80vh;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  z-index: 1000;
  height: max-content;
  overflow-y: auto;
  background: var(--neutral-100);
  width: max-content;

  &.small .container {
    max-width: 606px;
    padding: 56px 72px 48px;

    @include tablet {
      padding: 32px 48px 24px;
    }

    @include mobile {
      padding: 32px 0 24px;
    }
  }

  &.large .container {
    width: 744px;
    padding: 48px 96px;

    @include tablet {
      max-width: 744px;
      padding: 32px 48px 24px;
    }

    @include mobile {
      padding: 32px 0 24px;
    }
  }

  &.medium .container {
    padding: 32px 96px;

    @include tablet {
      padding: 32px 72px;
    }

    @include mobile {
      padding: 32px 25px;
    }
  }

  &.none .container {
    padding: 0;
  }

  @include mobile {
    margin: auto 24px;
    width: calc(100% - 48px);
  }

  & .header {
    position: relative;

    button {
      width: 24px;
      height: 24px;
      position: absolute;
      right: 22px;
      top: 16px;
    }
  }

  & .container {
    display: flex;
    flex-direction: column;
    align-items: center;

    & .footer {
      display: flex;
      justify-content: center;
      gap: 16px;
      width: 100%;

      button {
        padding: 10px 24px;
        min-width: 120px;
      }
    }
  }
}

.overlay {
  overflow: hidden;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, .5);
  z-index: 200;
}
