
                    @import "src/styles/_mixins.scss";
                
.wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;

  .header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 8px;

    .text_wrapper {
      display: flex;
      flex-direction: column;
      gap: 10px;
    }

    .actions {
      display: flex;
      align-items: center;
      gap: 16px;
      width: max-content;

      .search {
        max-width: max-content;
        input {
          width: 400px;
        }
      }

      .date {
        max-width: max-content;
        input {
          width: 300px;
        }
      }
    }
  }

  .drawer {
    display: flex;
    flex-direction: column;
    gap: 24px;

    .date_accordion {
      display: flex;
      align-items: center;
      gap: 16px;
      margin-bottom: 24px;
    }
  }
}
